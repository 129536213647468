@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

// for presentation only

body {marging-top: 2rem;}

$menu-background: #151B54;

.title-bar {
  background: $menu-background;
  padding: 0.9rem;
}

.top-bar {
  background: $menu-background;
  
  ul {
    background: $menu-background;
  
    li {
      background: $menu-background;
    
        a {
          color: #fff;
      }
    }
  }
}

.menu-text {
  color: #fff;
  
  @media only screen and (max-width: 40em) {
    display: none !important;
  }
}

@media only screen and (min-width: 40em) {
  .menu:last-child {
    border-left: 1px solid #4e4e4e;
  }

  .menu:first-child {
    border-left: none;
  }

  .menu {
    li:not(:last-child) {
      border-right: 1px solid #4e4e4e;
    }
  }
}

.dropdown.menu .submenu {
  border: none;
}

.dropdown.menu .is-dropdown-submenu-parent.is-right-arrow>a::after {
  border-color: #fff transparent transparent;
}

.is-drilldown-submenu-parent>a::after {
  border-color: transparent transparent transparent #fff;
}

.js-drilldown-back::before {
  border-color: transparent #fff transparent transparent;
}


#wip_image {
    padding-top: 50px;
}

