// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group grid
////

/// Outputs CSS classes for the grid.
/// @access private
@mixin foundation-grid(
  $row: 'row',
  $column: 'column',
  $column-row: 'column-row',
  $push: 'push',
  $pull: 'pull',
  $center: 'centered',
  $uncenter: 'uncentered',
  $collapse: 'collapse',
  $uncollapse: 'uncollapse',
  $offset: 'offset',
  $end: 'end',
  $expanded: 'expanded'
) {
  // Row
  .#{$row} {
    @include grid-row;

    // Collapsing
    &.#{$collapse} {
      > .#{$column} { @include grid-col-collapse; }
    }

    // Nesting
    & & {
      @if $grid-column-gutter == null {
        @each $breakpoint, $gutter in $grid-column-responsive-gutter {
          @include breakpoint($breakpoint) {
            @include grid-row-nest($gutter);
          }
        }
      }
      @else {
        @include grid-row-nest($grid-column-gutter);
      }

      &.#{$collapse} {
        margin-left: 0;
        margin-right: 0;
      }
    }

    // Expanded (full-width) row
    &.#{$expanded} {
      max-width: none;
    }
  }

  // Column
  .#{$column} {
    @include grid-col;

    @if $grid-column-align-edge {
      &.#{$end} {
        @include grid-col-end;
      }
    }
  }

  // Column row
  // The double .row class is needed to bump up the specificity
  .#{$column}.#{$row}.#{$row} {
    float: none;

    // To properly nest a column row, padding and margin is removed
    .#{$row} & {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include -zf-each-breakpoint {
    @for $i from 1 through $grid-column-count {
      // Column width
      .#{$-zf-size}-#{$i} {
        @include grid-col-size($i);
      }

      // Source ordering
      @if $i < $grid-column-count {
        .#{$-zf-size}-#{$push}-#{$i} {
          @include grid-col-pos($i);
        }

        .#{$-zf-size}-#{$pull}-#{$i} {
          @include grid-col-pos(-$i);
        }
      }

      // Offsets
      $o: $i - 1;

      .#{$-zf-size}-#{$offset}-#{$o} {
        @include grid-col-off($o);
      }
    }

    // Block grid
    @for $i from 1 through $block-grid-max {
      .#{$-zf-size}-up-#{$i} {
        @include grid-layout($i);
      }
    }

    // Responsive collapsing
    .#{$-zf-size}-#{$collapse} {
      > .#{$column} { @include grid-col-collapse; }
    }

    .#{$-zf-size}-#{$uncollapse} {
      $gutter: null;
      
      @if $grid-column-gutter {
        $gutter: $grid-column-gutter;
      }
      @else {
        $gutter: -zf-get-bp-val($grid-column-responsive-gutter, $-zf-size);
      }
      > .#{$column} { @include grid-col-uncollapse($gutter); }
    }

    // Positioning
    .#{$-zf-size}-#{$center} {
      @include grid-col-pos(center);
    }

    // Gutter adjustment
    .#{$-zf-size}-#{$uncenter},
    .#{$-zf-size}-#{$push}-0,
    .#{$-zf-size}-#{$pull}-0 {
      @include grid-col-unpos;
    }
  }

  @if $column == 'column' {
    .columns {
      // scss-lint:disable PlaceholderInExtend
      @extend .column;
    }
  }
}
